import { AfterViewInit, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { getStudentCategory } from 'src/app/utils';
import { environment } from 'src/environments/environment';

import { DabarKoordinatorService } from '../../services/dabar-koordinator.service';
import { DabarKoordinatorAuthService } from '../../services/dabar.koordinator.auth.service';

@Component({
  selector: 'app-student-results',
  templateUrl: './student-results.component.html',
  styleUrls: ['./student-results.component.scss']
})
export class StudentResultsComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('nameInput', { static: false })
  nameInput: ElementRef<HTMLInputElement>
  @ViewChild('surnameInput', { static: false })
  surnameInput: ElementRef<HTMLInputElement>

  displayedColumns: string[] = ['username', 'ime', 'prezime', 'razred', 'odjeljenje', 'kategorija', 'rang_cat', 'rang_cat_school'];
  dataSource: MatTableDataSource<any>;

  editingName: { [id: string]: boolean } = {}
  editingSurname: { [id: string]: boolean } = {}
  originalName: string
  originalSurname: string
  hello: any[];
  dabarKod: string;
  myUuid: string
  klokan = environment.klokan

  constructor(
    private koordinatorAPI: DabarKoordinatorService,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    private snackbar: MatSnackBar,
  ) {
    if (environment.klokan) {
      this.displayedColumns.push('details')
    }
  }

  ngAfterViewInit() {
    this.myUuid = this.dabarKoordAuthAPI.getCurrentUserId();
    this.koordinatorAPI.getStudents(this.myUuid)
      .subscribe((students) => {
        this.hello = [];
        students.forEach(item => {
          let data = item.payload.doc.data();// as TestingCenter;
          const _id = item.payload.doc.id;
          data["_id"] = _id;
          data.kategorija = this.changeKategory(data.kategorija)
          this.hello.push(data);
        })
        //let a = item.toJSON();category: Category[];
        this.dataSource = new MatTableDataSource(this.hello);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    this.dabarKoordAuthAPI.getMyKoordinator().subscribe(koord => {
      this.dabarKod = koord.data().dabarKod
    })
  }

  onEnterSurname(row) {
    this.originalSurname = row.ime
    this.toggleEditSurname(row, false)
    this.koordinatorAPI.updateStudent(this.myUuid, row._id, { prezime: row.prezime })
      .then(() => {
        this.snackbar.open("Izmjene spasene za " + row.ime + " " + row.prezime, undefined, {
          panelClass: 'snack-success',
          duration: 5000,
        })
      })
      .catch(() => {
        this.snackbar.open("Izmjene NISU uspjesno spasene, desila se neka greska.", undefined, {
          panelClass: 'snack-error',
          duration: 5000,
        })
      })
  }

  onEnterName(row) {
    this.originalName = row.ime
    this.toggleEditName(row, false)
    this.koordinatorAPI.updateStudent(this.myUuid, row._id, { ime: row.ime })
      .then(() => {
        this.snackbar.open("Izmjene spasene za " + row.ime + " " + row.prezime, undefined, {
          panelClass: 'snack-success',
          duration: 5000,
        })
      })
      .catch(() => {
        this.snackbar.open("Izmjene NISU uspjesno spasene, desila se neka greska.", undefined, {
          panelClass: 'snack-error',
          duration: 5000,
        })
      })
  }

  toggleEditName(row, displayWarning = true) {
    // from off to on
    if (!this.editingName[row._id]) {
      this.originalName = row.ime
      setTimeout(() => {
        this.nameInput.nativeElement.focus()
      }, 200)
    } else {
      if (displayWarning) {
        this.snackbar.open("Izmjene NISU spasene. Da biste spasili izmjene pritisnite ENTER nakon unosa imena.", undefined, {
          duration: 5000,
          panelClass: "snack-warn"
        })
      }
      row.ime = this.originalName
      delete this.originalName
    }
    this.editingName[row._id] = !this.editingName[row._id]
  }

  toggleEditSurname(row, displayWarning = true) {
    // from off to on
    if (!this.editingSurname[row._id]) {
      this.originalSurname = row.ime
      setTimeout(() => {
        this.surnameInput.nativeElement.focus()
      }, 200)
    } else {
      if (displayWarning) {
        this.snackbar.open("Izmjene NISU spasene. Da biste spasili izmjene pritisnite ENTER nakon unosa imena.", undefined, {
          duration: 5000,
          panelClass: "snack-warn"
        })
      }
      row.ime = this.originalSurname
      delete this.originalSurname
    }
    this.editingSurname[row._id] = !this.editingSurname[row._id]
  }

  changeKategory(kategorija) {
    return getStudentCategory(kategorija)
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
