import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";

@Component({
    selector: "zadatak-deseti",
    templateUrl: "zadatak-deseti.html",
    styleUrls: ["./zadaci.scss"]
})

export class ZadatakDeseti{
    public answer="";
    public mode="Prikaži";

    constructor(public dialogRef: MatDialogRef<ZadatakDeseti>) {}

    onNoClick():void {
        this.dialogRef.close();
    }

    showAnswer() {
        if(this.answer==""){
            this.answer="show";
            this.mode="Sakrij";
        }else{
            this.answer="";
            this.mode="Prikaži";
        }
    }
}
