import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DabarKoordinatorService } from 'src/app/services/dabar-koordinator.service';
import { DabarKoordinatorAuthService } from 'src/app/services/dabar.koordinator.auth.service';

@Component({
  selector: 'app-booklet-order',
  templateUrl: './booklet-order.component.html',
  styleUrls: ['./booklet-order.component.scss']
})
export class BookletOrderComponent implements OnInit {
  myUid: string
  successMessage: string
  errorMessage: string
  form = new FormGroup({
    bookletAmount: new FormControl()
  })
  constructor(
    private koord: DabarKoordinatorAuthService,
    private koordApi: DabarKoordinatorService,
  ) {}

  ngOnInit() {
    this.koord.getMyKoordinator().subscribe(koord => {
      this.form.setValue({
        bookletAmount: koord.data().bookletAmount || 0
      })
      this.myUid = koord.id
    })
  }

  async submit() {
    delete this.errorMessage
    delete this.successMessage
    try {
      await this.koordApi.updateKoordinatorData(this.myUid, this.form.value)
      this.successMessage = "Izmjene uspješno spašene!"
    } catch(err) {
      this.errorMessage = err.message
    }
  }
}
