import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FIREBASE_REFERENCES } from 'src/app/core/firebase/firebase.module';
import { DabarKoordinatorService } from 'src/app/services/dabar-koordinator.service';
import { DabarKoordinatorAuthService } from 'src/app/services/dabar.koordinator.auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-osnovni-podaci',
  templateUrl: './osnovni-podaci.component.html',
  styleUrls: ['./osnovni-podaci.component.scss']
})
export class OsnovniPodaciComponent implements OnInit {
  editKoordinatorForm: FormGroup;
  originalUserData: any;
  klokan = environment.klokan
  private koordId: string

  constructor(
    private fb: FormBuilder,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    private dabarKoordinatorAPI: DabarKoordinatorService,
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORE) private readonly dabarKoorAPIDirect: AngularFirestore,
  ) {
  }

  ngOnInit() {
    this.createKoordinatorForm();
    this.dabarKoordAuthAPI.currentUser$.pipe(
      first(v => !!v)
    ).subscribe(() => {
      this.dabarKoordAuthAPI.getMyKoordinator()
        .subscribe((user) => {
          this.originalUserData = user.data();
          this.koordId = user.id
          this.resetForm();
        });
    })
  }


  resetForm() {
    this.createKoordinatorForm();
    this.editKoordinatorForm.patchValue(this.originalUserData);
  }


  /* create form */
  createKoordinatorForm() {
    this.editKoordinatorForm = this.fb.group({
      participants2017: [''],
      participants2018: [''],
      schoolAddress: ['', [Validators.required]],
      schoolCity: ['Yes'],
      schoolEmail: [''],
      schoolName: ['', [Validators.required]],
      schoolTotNmbStudents: [''],
      schoolWeb: [''],
      schoolZip: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      helper1: [''],
      helper2: [''],
      helper3: [''],
      helper4: [''],
      helper5: [''],
      helper6: [''],
      helper7: [''],
      paymentType: [null],
      paymentAmount: [null],
    })
    this.editKoordinatorForm.valueChanges.subscribe(() => {
      setTimeout(() => {
        if (this.editKoordinatorForm && this.editKoordinatorForm.value.paymentType == 'delivery') {
          this.editKoordinatorForm.controls.paymentAmount.setErrors(
            this.editKoordinatorForm.value.paymentAmount ? null : {
              'required': true
            }
          )
        } else {
          this.editKoordinatorForm.controls.paymentAmount.setErrors(null)
        }
      }, 100)
    })
  }

  updateEditKoordinatorData() {
    if (this.editKoordinatorForm.invalid) {
      alert("Nisu sva polja uredu. Molimo provjerite polja oznacena crvenom bojom.")
      return
    }
    this.dabarKoordinatorAPI.updateKoordinatorData(this.dabarKoordAuthAPI.getCurrentUserId(), this.editKoordinatorForm.value)
    this.originalUserData = this.editKoordinatorForm.value

    this.resetForm()
  }

  bringBackOriginalDataForUser() {
    this.resetForm()
  }

  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.editKoordinatorForm.controls[controlName].hasError(errorName);
  }
}
