import { Injectable, Inject } from '@angular/core';
import { FIREBASE_REFERENCES } from '../core/firebase/firebase.module';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanActivate } from '@angular/router';

import * as firebase from 'firebase/app';

import { DabarKoordinatorService } from './dabar-koordinator.service';

import { Router } from '@angular/router';
import { filter, first, map, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DabarKoordinatorAuthService implements CanActivate {

  private authState: Observable<firebase.User>;
  // undefined means we don't know (page has just loaded)
  // null means user is not signed in
  private currentUserSubject$ = new BehaviorSubject<firebase.User>(undefined);
  currentUser$: Observable<firebase.User> = this.currentUserSubject$;

  constructor(
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIREAUTH) private koorAuth: AngularFireAuth,
    private dabarKoorAPI: DabarKoordinatorService
  ) {
    this.authState = this.koorAuth.authState;
    this.authState.subscribe(user => {
      this.currentUserSubject$.next(user);
    });
  }

  getAuthState() {
    return this.authState;
  }

  getCurrentUser() {
    return this.currentUserSubject$.value;
  }

  getCurrentUserId() {
    return localStorage.getItem('impersonate') || this.getCurrentUser().uid
  }

  getMyKoordinator() {
    return this.dabarKoorAPI.getKoordinator(this.getCurrentUserId())
  }

  async logIn(user: any) {
    const ret = await this.koorAuth.auth.signInWithEmailAndPassword(user.username, user.password);
    if (!ret.user.emailVerified) {
      this.koorAuth.auth.signOut();
      throw new Error(`Molimo vas da potvrdite vas email. `
        + `Potrebno je da kliknete link koji smo vam poslali s naseg sistema. `
        + `Email obicno dodje u roku od 30 min. `
        + `U koliko ne vidite email, provjerite vas spam folder (nezeljena posta). `
        + `U koliko i dalje ne vidite email, molimo da nas kontaktirate na email `
        + `klokan.matematicar.ba@gmail.com s emaila ${user.username} i reci nam da imate gresku: NEPOTVRDJEN_EMAIL.`)
    }
    return ret
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.koorAuth.auth.currentUser.sendEmailVerification()
  }

  async signUp(user: any) {
    await this.koorAuth.auth.createUserWithEmailAndPassword(user.email, user.password);
    const userWithoutPassword = {...user}
    delete userWithoutPassword.password
    await Promise.all([
      this.koorAuth.auth.currentUser.updateProfile({
        displayName: user.name
      }),
      this.SendVerificationMail(),
      this.dabarKoorAPI.addNewKoordinator(this.koorAuth.auth.currentUser.uid, {
        ...userWithoutPassword,
        obavezanDabarKod: true,
      })
    ]);
    await this.koorAuth.auth.signOut();
  }

  logOut() {
    if (localStorage.getItem('impersonate')) {
      localStorage.removeItem('impersonate')
    } else {
      this.koorAuth.auth.signOut();
    }
    setTimeout(() => {
      location.reload();
    }, 100)
  }

  googleLogin() {
    this.koorAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  forgotPassword(passwordResetEmail) {
    return this.koorAuth.auth.sendPasswordResetEmail(passwordResetEmail)
  }

  canActivate(): Observable<boolean> {
    return this.currentUser$.pipe(
      filter(v => v !== undefined),
      map(user => {
        if (!user) {
          return false;
        }
        if (!user.emailVerified) {
          return false;
        }
        return true;
      })
    )
  }
}
