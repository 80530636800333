import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  webinarLink = "https://gimmcc-my.sharepoint.com/:v:/g/personal/estela_ramic_mcc_edu_ba/EQvhIKU3rlxKqrP5EIwc0ZsBDvaJyhx5luEheJXHJ9CADQ?e=le0JR9"

  constructor() { }

  ngOnInit() {
  }

}
