import {InjectionToken, NgModule, NgZone, PLATFORM_ID} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ENV, ENV_CONFIG} from '../env';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAnalytics, AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';


export function AngularFirestoreFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFirestore(env.firebaseConfig[projectId], projectId, false, null, platformId, zone, null);
}

export function AngularFireAuthFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireAuth(env.firebaseConfig[projectId], projectId, platformId, zone);
}

export function AngularFirestorageFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireStorage(env.firebaseConfig[projectId], projectId, platformId, false, zone);
}

export const FIREBASE_DABAR_KOORDINATORS = new InjectionToken('firebase project injector');

export const FIREBASE_REFERENCES = {
  DABAR_KOORDINATORS_FIRESTORE: 'dabar-koordinators-firestore',
  DABAR_KOORDINATORS_FIREAUTH: 'dabar-koordinators-fireauth',
  DABAR_KOORDINATORS_FIRESTORAGE: 'dabar-koordinators-firestorage',
};

@NgModule({
  declarations: [],
  providers: [
    {
      provide: FIREBASE_DABAR_KOORDINATORS, useValue: 'KOORDINATORS'
    },
    {
      provide: FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_DABAR_KOORDINATORS],
      useFactory: AngularFirestoreFactory
    },
    {
      provide: FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_DABAR_KOORDINATORS],
      useFactory: AngularFireAuthFactory
    },
    {
      provide: FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORAGE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_DABAR_KOORDINATORS],
      useFactory: AngularFirestorageFactory
    },
    ScreenTrackingService,
    UserTrackingService,
  ],
  imports: [
    CommonModule,
    AngularFireAnalyticsModule,
  ]
})
export class FirebaseModule {
}
