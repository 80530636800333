import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog,  } from '@angular/material';
import { LoginKoordinatoraComponent } from '../login-koordinatora/login-koordinatora.component';
import { Router } from '@angular/router';
import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';
import { environment } from 'src/environments/environment';
import { DabarKoordinatorService } from '../services/dabar-koordinator.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [
    './navbar.component.scss',
    './navbar-link/navbar-link.component.scss',
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {

  klokan = environment.klokan
  email = environment.klokan ? "klokan.matematicar.ba@gmail.com" : "dabarinformaticarbih@gmail.com"

  usernameKoor: string = undefined;
  koordCanActivate: boolean = false;

  constructor(public dialog: MatDialog,
    public router: Router,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    private dabarKoordAPI: DabarKoordinatorService,
  ) {
  }

  ngOnInit() {
    this.dabarKoordAuthAPI.canActivate().subscribe(v => this.koordCanActivate = v)
    this.dabarKoordAuthAPI.getAuthState()
      .subscribe((user) => {
        if (user) {
          // User is signed in.
          this.dabarKoordAuthAPI.getMyKoordinator().pipe(first()).subscribe(koord => {
            this.usernameKoor = koord.get('name') || koord.get('email');
          })
        } else {
          this.usernameKoor = undefined;
        }
      });
  }

  ngOnDestroy() {
  }

  openKoordinatorLoginForm() {
    if (this.usernameKoor) {
      this.router.navigateByUrl('/koordinator');
    } else {
      const loginRef = this.dialog.open(LoginKoordinatoraComponent, { width: '450px', height: '550px' });
    }
  }

  logOutKoordinator() {
    this.usernameKoor = undefined;
    this.dabarKoordAuthAPI.logOut();
    this.router.navigateByUrl('/home');
  }
}
