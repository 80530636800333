import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { KoordDashboardComponent } from '../koord-dashboard/koord-dashboard.component';
import { StudentResultsComponent } from '../koord-dashboard/student-results/student-results.component';
import { UploadFilesComponent } from '../koord-dashboard/upload-files/upload-files.component';
import { OsnovniPodaciComponent } from '../koord-dashboard/osnovni-podaci/osnovni-podaci.component';


const routes: Routes = [{
  component: KoordDashboardComponent,
  path: 'koordinator',
  children: [
    { path: 'students-results', component: StudentResultsComponent },
    { path: 'upload-files', component: UploadFilesComponent },
    { path: 'osnovni-podaci', component: OsnovniPodaciComponent },
    { path: '', component: OsnovniPodaciComponent, pathMatch: 'full' },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KoordDashboardRoutingModule { }
