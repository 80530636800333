import { Injectable, Inject } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FIREBASE_REFERENCES} from '../core/firebase/firebase.module';

import { Observable } from 'rxjs';

import * as firebase from 'firebase/app';

import { QuerySnapshot } from '@firebase/firestore-types';
import { first } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class DabarKoordinatorService {

  name_of_students_collection = "students2024"

  constructor(
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORE) private readonly dabarKoorAPI: AngularFirestore,
  )  { }

  getKoordinators() {
    return this.dabarKoorAPI.collection('koordinators').get()
  }
  addNewKoordinator(uid, obfffj) {
    const data = {...obfffj, createdAt: firebase.firestore.FieldValue.serverTimestamp()}
    return this.dabarKoorAPI.collection('koordinators').doc(uid).set(data)
  }

  getStudents(uuid): Observable<any> {
    //return this.dabarKoorAPI.collection('koordinators').doc(this.authService.getCurrentUser().uid).collection('students' + year).snapshotChanges();
    return this.dabarKoorAPI.collection('koordinators').doc(uuid).collection(this.name_of_students_collection).snapshotChanges();
  }

  async getStudentByUsername(koordUuid: string, username: string) {
    const ret = await this.dabarKoorAPI
      .collection('koordinators')
      .doc(koordUuid)
      .collection(this.name_of_students_collection, ref => ref.where('username', '==', username))
      .get()
      .pipe(first())
      .toPromise()
    return ret.docs[0]
  }

  updateStudent(koordUuid, studentUuid, changes) {
    //return this.dabarKoorAPI.collection('koordinators').doc(this.authService.getCurrentUser().uid).collection('students' + year).snapshotChanges();
    return this.dabarKoorAPI.collection('koordinators').doc(koordUuid).collection(this.name_of_students_collection).doc(studentUuid).update(changes);
  }

  getKoordinator(uuid) {
    return this.dabarKoorAPI.collection('koordinators').doc(uuid).get();
  }

  async updateKoordinatorData(uuid, data) {
    const koordinatorRef = this.dabarKoorAPI.collection('koordinators').doc(uuid);
    return await koordinatorRef.update(data)
  }

  getDataAboutKoordinators() {
    const citiesRef = this.dabarKoorAPI.collection('koordinators');

    var dict = {}
    const snapshot = citiesRef.get()
      .subscribe((querySnapshot: QuerySnapshot<any>) => {
        querySnapshot.forEach((doc: any) => {
          // doc.data() is never undefined for query doc snapshots
          dict[doc.data().dabarKod] = doc.data().studentKod
        })
        return dict;
      });


  }

  async getKoordinatorByDabarKod(kod: string) {
    const ret = await this.dabarKoorAPI
      .collection('koordinators', ref => ref.where('dabarKod', '==', kod))
      .get()
      .pipe(first())
      .toPromise()
    return ret.docs[0]
  }
}
