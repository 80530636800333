import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { DabarKoordinatorService } from '../services/dabar-koordinator.service';
import { getStudentCategory } from '../utils';

interface Answer {
  gaveAnswer: string
  verdict: string
  cssClass: string
}

@Component({
  selector: 'app-klokan-provjera-rezultata',
  templateUrl: './klokan-provjera-rezultata.component.html',
  styleUrls: ['./klokan-provjera-rezultata.component.scss']
})
export class KlokanProvjeraRezultataComponent implements OnInit {
  form = new FormGroup({
    studentCode: new FormControl(),
  })
  student: any
  studentId: string
  koordId: string
  kategorija: string
  odgovori: Answer[]

  constructor(
    private koordApi: DabarKoordinatorService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(first()).subscribe(params => {
      if (!!params.studentCode) {
        this.form.patchValue({ studentCode: params.studentCode })
        this.onSubmit()
      }
    })
  }

  async onSubmit() {
    this.form.disable()
    delete this.student
    try {
      let code: string = this.form.value.studentCode
      if (!!!code) {
        throw new Error("Molimo unesite kod")
      }
      code = code.trim().toUpperCase()
      const splits = code.split('_')
      if (
        splits.length != 3 ||
        splits[0].length != 3 ||
        splits[1].length != 1 ||
        !"KPEBCJS".includes(splits[1])
      ) {
        throw new Error("Format koda nije pravilan")
      }
      const schoolCode = splits[0]
      const koord = await this.koordApi.getKoordinatorByDabarKod(schoolCode)
      if (!koord) {
        throw new Error("Skola sa kodom " + schoolCode + " ne postoji")
      }
      this.koordId = koord.id
      const student = await this.koordApi.getStudentByUsername(this.koordId, code)
      console.log(this.koordId, code);
      if (!student) {
        throw new Error("Ucenik s tim kodom nije pronadjen")
      }
      this.student = student.data()
      this.studentId = student.id
      this.kategorija = getStudentCategory(this.student.kategorija)
      this.loadAnswers()
    } catch(err) {
      alert(err.message)
      console.error(err)
    } finally {
      this.form.enable()
    }
  }

  private fixStringArray(str: string): string[] {
    return JSON.parse(str
      .replace(/'/g, "\"")
      .replace(/,/g, "")
      .replace(/ /g, ",")
    ).map(String) as string[]
  }

  private loadAnswers() {
    const evaluation = this.fixStringArray(this.student.evaluacija)
    const answers = this.fixStringArray(this.student.odgovori)
    this.odgovori = []
    for (let i=0; i<evaluation.length; i++) {
      const ev = evaluation[i]
      const an = answers[i]
      if (ev == '-1') {
        this.odgovori.push({
          cssClass: 'text-danger',
          verdict: 'Netačno',
          gaveAnswer: an,
        })
      } else if (ev == '0') {
        this.odgovori.push({
          cssClass: 'text-warning',
          verdict: 'Neodgovoreno',
          gaveAnswer: an,
        })
      } else if (ev == '1') {
        this.odgovori.push({
          cssClass: 'text-success',
          verdict: 'Tačno',
          gaveAnswer: an,
        })
      } else {
        this.odgovori.push({
          cssClass: '',
          verdict: '???',
          gaveAnswer: an,
        })
      }
    }
  }

  async reportError(value: boolean) {
    await this.koordApi.updateStudent(this.koordId, this.studentId, {needsChecking: value})
    this.student.needsChecking = value
  }
}
