import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  klokan: boolean = false; // Initialize klokan with a default value
  constructor() { }

  ngOnInit() {
    // Here you might set klokan based on some condition or user action
    // For demonstration, I'm setting it to true directly
    // In a real scenario, you would likely check some condition or state
    this.klokan = this.checkKlokanCondition();
  }
  checkKlokanCondition(): boolean {
    // Implement the logic to determine if klokan should be true or false
    // This is just a placeholder function for your actual logic
    return true; // Example condition check
  }
}
