import { Component, OnInit } from '@angular/core';
import { ZadatakPrvi } from './zadaci/zadatak-prvi';
import { MatDialog } from '@angular/material';
import { ZadatakDrugi } from './zadaci/zadatak-drugi';
import { ZadatakTreci } from './zadaci/zadatak-treci';
import { ZadatakCetvrti } from './zadaci/zadatak-cetvrti';
import { ZadatakPeti } from './zadaci/zadatak-peti';
import { ZadatakSesti } from './zadaci/zadatak-sesti';
import { ZadatakSedmi } from './zadaci/zadatak-sedmi';
import { ZadatakOsmi } from './zadaci/zadatak-osmi';
import { ZadatakDeveti } from './zadaci/zadatak-deveti';
import { ZadatakDeseti } from './zadaci/zadatak-deseti';

interface Zadatak {
    nameComponent: string;
    title: string
}
@Component({
  selector: 'app-primjeri',
  templateUrl: './primjeri.component.html',
  styleUrls: ['./primjeri.component.scss']
})
export class PrimjeriComponent implements OnInit {

  zadaci: Zadatak[] = [];
  sizeOfWindow: any;

  constructor(public dialog: MatDialog) {

    this.zadaci.push({nameComponent:"ZadatakPrvi", title: 'Promjena pravca'});
    this.zadaci.push({nameComponent:'ZadatakDrugi', title: 'Moderna bicikla u Gradu Dabrova'});
    this.zadaci.push({nameComponent:'ZadatakTreci', title: 'Sađenje cvijeća'});
    this.zadaci.push({nameComponent:'ZadatakCetvrti', title: 'Razdvoji'});
    this.zadaci.push({nameComponent:'ZadatakPeti', title: 'Rotirajuća puzla'});
    this.zadaci.push({nameComponent:'ZadatakSesti', title: 'Dabrovska navigacija'});
    this.zadaci.push({nameComponent:'ZadatakSedmi', title: 'Tekst mašina'});
    this.zadaci.push({nameComponent:'ZadatakOsmi', title: 'Aerodrom'});
    this.zadaci.push({nameComponent:'ZadatakDeveti', title: 'Riječna inspekcija'});
    this.zadaci.push({nameComponent:'ZadatakDeseti', title: 'Posjeta prijateljima'});


  }

  ngOnInit() {
  }

  OpenZadatak(nameOfComponent) {
    this.sizeOfWindow = {width: '1050px', height: '850px'};
    switch (nameOfComponent) {
     case "ZadatakPrvi": {
        const loginRef = this.dialog.open(ZadatakPrvi, this.sizeOfWindow);
        break;
     }
     case "ZadatakDrugi": {
        const loginRef = this.dialog.open(ZadatakDrugi, this.sizeOfWindow);
        break;
     }
     case "ZadatakTreci": {
        const loginRef = this.dialog.open(ZadatakTreci, this.sizeOfWindow);
        break;
     }
     case "ZadatakCetvrti": {
        const loginRef = this.dialog.open(ZadatakCetvrti, this.sizeOfWindow);
        break;
     }
     case "ZadatakPeti": {
        const loginRef = this.dialog.open(ZadatakPeti, this.sizeOfWindow);
        break;
     }
     case "ZadatakSesti": {
        const loginRef = this.dialog.open(ZadatakSesti, this.sizeOfWindow);
        break;
     }
     case "ZadatakSedmi": {
        const loginRef = this.dialog.open(ZadatakSedmi, this.sizeOfWindow);
        break;
     }
     case "ZadatakOsmi": {
        const loginRef = this.dialog.open(ZadatakOsmi, this.sizeOfWindow);
        break;
     }
     case "ZadatakDeveti": {
        const loginRef = this.dialog.open(ZadatakDeveti, this.sizeOfWindow);
        break;
     }
     case "ZadatakDeseti": {
        const loginRef = this.dialog.open(ZadatakDeseti, this.sizeOfWindow);
        break;
     }
     default: {
        //statements;
        break;
     }
 }
}

  openFirst(){
     //this.dialog.open(ZadatakPrvi);

  }

  openSecond(){
    this.dialog.open(ZadatakDrugi);
  }

  openThird(){
    this.dialog.open(ZadatakTreci);
  }

  openFourth(){
    this.dialog.open(ZadatakCetvrti);
  }

  openFifth(){
    this.dialog.open(ZadatakPeti);
  }

  openSixth(){
    this.dialog.open(ZadatakSesti);
  }

  openSeventh(){
    this.dialog.open(ZadatakSedmi);
  }

  openEight(){
    this.dialog.open(ZadatakOsmi);
  }

  openNinth(){
    this.dialog.open(ZadatakDeveti);
  }

  openTenth(){
    this.dialog.open(ZadatakDeseti);
  }
}
