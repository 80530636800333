/* import { ViewEncapsulation, Component, Inject } from '@angular/core';

import { FIREBASE_REFERENCES } from '../core/firebase/firebase.module';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';


import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';


import { FormBuilder } from "@angular/forms";
//import * as dashboard from 'dashboard';
//import { IconsFeatherModule } from '../icons-feather/icons-feather.module';
import * as Feather from 'feather-icons';
import { ActivationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-koord-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './koord-dashboard.component.html',
  styleUrls: ['./koord-dashboard.component.scss']
})
export class KoordDashboardComponent {
  selection: string = 'basic';

  dabarKod: string;
  isAdmin: boolean

  userDisplayName: string = undefined;
  useremail: string = undefined;

  klokan = environment.klokan

  constructor(
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORE) private readonly dabarKoorAPIDirect: AngularFirestore,
    public fb: FormBuilder,
    router: Router,
  ) {
    router.events.subscribe((evt) => {
      if (evt instanceof ActivationEnd) {
        if (evt.snapshot.url &&
            evt.snapshot.url[0] &&
            !!evt.snapshot.url[0].path &&
            evt.snapshot.url[0].path != 'koordinator'
        ) {
          this.selection = evt.snapshot.url[0].path
        }
      }
    })
    this.dabarKoordAuthAPI.getAuthState()
      .pipe(first(user => !!user))
      .subscribe(() => {
        this.dabarKoordAuthAPI.getMyKoordinator()
          .subscribe((koordinator) => {
            if (koordinator) {
              const koordinatorData = koordinator.data()
              this.dabarKod = koordinatorData.dabarKod
              this.isAdmin = !!koordinatorData.admin
              this.userDisplayName = koordinatorData.name
              this.useremail = koordinatorData.email
              console.log(this.useremail, this.userDisplayName, koordinatorData.uuid );
            }
            setTimeout(() => Feather.replace(), 200);
          })
      })
  }

  ngAfterViewInit() {
    Feather.replace();
  }
}
 */
import { ViewEncapsulation, Component, Inject } from '@angular/core';
import { FIREBASE_REFERENCES } from '../core/firebase/firebase.module';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';
import { FormBuilder } from "@angular/forms";
import * as Feather from 'feather-icons';
import { ActivationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common'; // Import DatePipe

@Component({
  selector: 'app-koord-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './koord-dashboard.component.html',
  styleUrls: ['./koord-dashboard.component.scss']
})
export class KoordDashboardComponent {
  selection: string = 'basic';
  dabarKod: string;
  isAdmin: boolean;
  userDisplayName: string = undefined;
  useremail: string = undefined;
  klokan = environment.klokan;

  constructor(
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORE) private readonly dabarKoorAPIDirect: AngularFirestore,
    public fb: FormBuilder,
    router: Router,
    private datePipe: DatePipe // Inject DatePipe
  ) {
    router.events.subscribe((evt) => {
      if (evt instanceof ActivationEnd) {
        if (evt.snapshot.url &&
          evt.snapshot.url[0] &&
          !!evt.snapshot.url[0].path &&
          evt.snapshot.url[0].path != 'koordinator'
        ) {
          this.selection = evt.snapshot.url[0].path
        }
      }
    })
    this.dabarKoordAuthAPI.getAuthState()
      .pipe(first(user => !!user))
      .subscribe(() => {
        this.dabarKoordAuthAPI.getMyKoordinator()
          .subscribe((koordinator) => {
            if (koordinator) {
              const koordinatorData = koordinator.data();
              this.dabarKod = koordinatorData.dabarKod;
              this.isAdmin = !!koordinatorData.admin;
              this.userDisplayName = koordinatorData.name;
              this.useremail = koordinatorData.email;
              console.log(this.useremail, this.userDisplayName, koordinatorData.uuid);
            }
            setTimeout(() => Feather.replace(), 200);
          })
      })
  }

  ngAfterViewInit() {
    Feather.replace();
  }

  formatDate(timestamp: any): string {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    return this.datePipe.transform(milliseconds, 'yyyy-MM-dd HH:mm:ss') || '';
  }
}
