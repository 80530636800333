import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";

@Component({
  selector: "zadatak-deveti",
  templateUrl: "zadatak-deveti.html",
  styleUrls: ["./zadaci.scss"]
})
export class ZadatakDeveti {
    public answer="";
    public mode="Prikaži";

    constructor(public dialogRef: MatDialogRef<ZadatakDeveti>) {}

    onNoClick():void {
        this.dialogRef.close();
    }

    showAnswer() {
        if(this.answer== ""){
            this.answer = "show";
            this.mode = "Sakrij";
        } else {
            this.answer = "";
            this.mode = "Prikaži";
        }
    }

}
