// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./environment-interface";

export const environment: IEnvironment = {
  production: false,
  firebaseConfig: {
    'KOORDINATORS': {
      apiKey: "AIzaSyALpO-LXJcPKpUJD7GXqJiUBKaxqNMVkyY",
      authDomain: "klokan-matematicar-2022.firebaseapp.com",
      projectId: "klokan-matematicar-2022",
      storageBucket: "klokan-matematicar-2022.appspot.com",
      messagingSenderId: "854080345139",
      appId: "1:854080345139:web:8cb60e2a7a75307a97ff46",
      measurementId: "G-40L8Y92755"
    }
  },
  deadlines: [
    //new Date(2023, 2, 16, 23, 59, 59), // 26.02.2023 23:59:59
    //new Date(2023, 2, 16, 23, 59, 59), // 04.03.2023 23:59:59
    new Date(2024, 2, 9, 23, 59, 59), // 08.03.2024 23:59:59
    new Date(2024, 2, 13, 23, 59, 59), // 08.03.2024 23:59:59
  ],
  klokan: true,
  backendEnvironment: 'klokan-2024',
  excelTemplate: '/assets/pdf/SpisakUcenikaUcesnikaKlokanMatematicar2024_KlokanKod_ImeSkole.xlsx',
};
