/* //import { Component, OnInit } from '@angular/core';
import { DEFAULT_RESIZE_TIME } from '@angular/cdk/scrolling';
//import { DatePipe } from '@angular/common';

import { Component, OnInit } from '@angular/core';
//import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admin', // Make sure this selector is unique and appropriate
  templateUrl: './app.component.html', // Reference the correct HTML template
  styleUrls: ['./app.component.scss'] // Reference the correct SCSS/CSS file
})
export class AppComponent implements OnInit {

  constructor() {
    // You can use this.datePipe in your component
  }

  ngOnInit() {
    // Component initialization logic
  }

 /*  // Example usage of DatePipe
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  } */

import { Component } from '@angular/core';

@Component({
  selector: 'app-root', // This should be 'app-root' for the main application component
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // Your main app component logic here
}

