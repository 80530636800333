import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DabarKoordinatorService } from 'src/app/services/dabar-koordinator.service';
import {} from '@angular/cdk/table/typings/table'
import { Subscription } from 'rxjs';
import { MatSort, MatTableDataSource } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { BACKEND_LOCATION, getDeadlineDateIndex, getSubmittedDate } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseAuth } from '@angular/fire';
import { DatePipe } from '@angular/common'; // Import DatePipe

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy, AfterViewInit {
  private sub = new Subscription()
  data: MatTableDataSource<any> = null
  tableHeight = '200px'
  columnsToDisplay = ['position', 'createdAt', 'requestDelete', 'id', 'email', 'lastDabarFile', 'deadlineIndex', 'name', 'dabarKod', 'emailSent', 'studentsInExcel', 'telephone', 'schoolTotNmbStudents', 'schoolName', 'schoolType', 'schoolCity', 'schoolZip', 'schoolAddress', 'schoolEmail', 'bookletAmount', 'impersonate']
  generiseSe = false
  klokan = environment.klokan

  @ViewChild('table', { static: false, read: ElementRef })
  table: ElementRef<HTMLTableElement>
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  dabarCodes: {[userId: string]: string} = {}

  constructor(
    private dabarKoordinatorAPI: DabarKoordinatorService,
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }
  

  ngOnInit() {
  }

  private osvjezi() {
    this.sub.add(this.dabarKoordinatorAPI.getKoordinators().subscribe(data => {
      this.data = new MatTableDataSource(
        data.docs.map(row => ({
          id: row.id,
          deadlineIndex: this.submittedDeadlineIndex(row.data()),
          ...row.data()
        }))
      )
      this.data.sort = this.sort
    }))
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const top = (this.table.nativeElement.getBoundingClientRect() as any).y || 0
      const max = window.innerHeight
      this.tableHeight = (max - top) + 'px'
    }, 200)
    this.osvjezi()
  }

  private zavrsiOperaciju() {
    setTimeout(() => {
      this.osvjezi()
      this.generiseSe = false
    }, 200)
  }

  async generisiKod(user) {
    this.generiseSe = true
    let trebaZavrsitiOperaciju = true
    try {
      const noviKod = this.dabarCodes[user.id]
      if (!noviKod || noviKod.length != 3) {
        throw new Error("Kod mora imati tacno 3 broja")
      }
      if (noviKod.search(/\D/) != -1) {
        throw new Error("Kod smije imati samo brojeve")
      }
      if (this.data.data.find(u => u.dabarKod == noviKod)) {
        throw new Error("Ovaj kod se vec negdje koristi")
      }
      await this.dabarKoordinatorAPI.updateKoordinatorData(user.id, {
        dabarKod: noviKod
      })
      await this.http.get(BACKEND_LOCATION + `/assets/scripts/send_mail.php?id=${user.id}&template=vas_unikatni_kod_je_dodijeljen&project=${environment.backendEnvironment}`)
        .toPromise()
        .catch(() => { throw new Error("Greska pri slanju emaila. Posaljite ga rucno, oznacite checkbox da ste poslali email i prijavite problem programeru.") })
      await this.stvarnoOznaciEmail(user, true)
      trebaZavrsitiOperaciju = false
    } catch(err) {
      alert(err.message)
    } finally {
      if (trebaZavrsitiOperaciju) {
        this.zavrsiOperaciju()
      }
    }
  }

  private async updateKoordinatorData(id: string, newValues) {
    this.generiseSe = true
    try {
      await this.dabarKoordinatorAPI.updateKoordinatorData(id, newValues)
    } catch(err) {
      alert(err.message)
    } finally {
      this.zavrsiOperaciju()
    }
  }

  private async stvarnoOznaciEmail(user, newValue: boolean) {
    await this.updateKoordinatorData(user.id, {
      emailSent: newValue
    })
  }

  async oznaciEmail(user, newEvent: Event) {
    const element = newEvent.target as HTMLInputElement
    const newValue = element.checked
    newEvent.preventDefault()
    element.checked = !newValue
    await this.stvarnoOznaciEmail(user, newValue)
  }

  async obrisiKorisnika(user, newEvent: Event) {
    const element = newEvent.target as HTMLInputElement
    const newValue = element.checked
    newEvent.preventDefault()
    element.checked = !newValue
    await this.updateKoordinatorData(user.id, {requestDelete: newValue})
  }

  submittedDeadlineIndex(user) {
    if (user.lastDabarFile) {
      return getDeadlineDateIndex(getSubmittedDate(user.lastDabarFile)) + 1
    }
  }

  setSchoolType(user, evt) {
    this.updateKoordinatorData(user.id, { schoolType: evt.target.value })
  }

  impersonate(user) {
    localStorage.setItem("impersonate", user.id)
    location.href = "/"
    location.reload()
  }
  formatDate(timestamp: any): string {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    return this.datePipe.transform(milliseconds, 'yyyy-MM-dd HH:mm:ss') || '';
  }
}
