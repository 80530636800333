import { Component, OnInit } from '@angular/core';

const tasks = {
  "2017": {
    "Sve verzije": {
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2017/PreEcolierBHS_2017.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2017/EcolierBHS_2017.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2017/BenjaminBHS_2017.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2017/CadetBHS_2017.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2017/JuniorBHS_2017.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2017/StudentBHS_2017.pdf",
    }
  },
  "2018": {
    "bosanski/srpski, latinica": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2018/FinalizedProblems_SkolaracBS.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2018/FinalizedProblems_PreEcolierBS.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2018/FinalizedProblems_EcolierBS.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2018/FinalizedProblems_BenjaminBS",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2018/FinalizedProblems_CadetBS.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2018/FinalizedProblems_JuniorBS.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2018/FinalizedProblems_StudentBS.pdf",
    },
    "босански/српски, ћирилица": {
      "2 разред основне школе, Школарац": "assets/zadaci/2018/FinalizedProblems_SkolaracBSCirilica.pdf",
    },
    "hrvatski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2018/FinalizedProblems_SkolaracHR.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2018/FinalizedProblems_PreEcolierHR.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2018/FinalizedProblems_EcolierHR.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2018/FinalizedProblems_BenjaminHR.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2018/FinalizedProblems_CadetHR.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2018/FinalizedProblems_JuniorHR.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2018/FinalizedProblems_StudentHR.pdf",
    },
  },
  "2019": {
    "bosanski/srpski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2019/SkolaracBS_2019.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2019/PreEcolierBS_2019.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2019/EcolierBS_2019.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2019/BenjaminBS_2019.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2019/CadetBS_2019.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2019/JuniorBS_2019.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2019/StudentBS_2019.pdf",
    },
    "hrvatski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2019/SkolaracHR_2019.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2019/PreEcolierHR_2019.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2019/EcolierHR_2019.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2019/BenjaminHR_2019.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2019/CadetHR_2019.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2019/JuniorHR_2019.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2019/StudentHR_2019.pdf",
    },
  },
  "2020": {
    "bosanski/srpski, latinica": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2020/SkolaracBHSbs_2020.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2020/PreEcolierBHSbs_2020.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2020/EcolierBHSbs_2020.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2020/BenjaminBHSbs_2020.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2020/CadetBHSbs_2020.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2020/JuniorBHSbs_2020.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2020/StudentBHSbs_2020.pdf",
    },
    "босански/српски, ћирилица": {
      "2 разред основне школе, Школарац": "assets/zadaci/2020/SkolaracBHScirilic_2020.pdf",
      "3 разред основне школе, Преецолиер": "assets/zadaci/2020/PreEcolierBHScirilic_2020.pdf",
      "4 и 5 разред основне школе, Ецолиер": "assets/zadaci/2020/EcolierBHScirilic_2020.pdf",
      "6 и 7 разред основне школе, Бењамин": "assets/zadaci/2020/BenjaminBHScirilic_2020.pdf",
      "8 и 9 разред основне школе, Цадет": "assets/zadaci/2020/CadetBHScirilic_2020.pdf",
      "1 и 2 разред средње школе, Јуниор": "assets/zadaci/2020/JuniorBHScirilic_2020.pdf",
      "3 и 4 разред средње школе, Студент": "assets/zadaci/2020/StudentBHScirilic_2020.pdf",
    },
    "hrvatski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2020/SkolaracBHShr_2020.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2020/PreEcolierBHShr_2020.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2020/EcolierBHShr_2020.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2020/BenjaminBHShr_2020.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2020/CadetBHShr_2020.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2020/JuniorBHShr_2020.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2020/StudentBHShr_2020.pdf",
    },
  },
  "2022": {
    "bosanski/srpski, latinica": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2022/Skolarac_BS_12.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2022/PreEcolier_BS_18.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2022/Ecolier_BS_18.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2022/BenjaminBHS_BS_24.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2022/CadetBHS__BS_24.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2022/JuniorBHS_BS_24.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2022/StudentBHS_BS_24.pdf",
    },
    "босански/српски, ћирилица": {
      "2 разред основне школе, Школарац": "assets/zadaci/2022/Skolarac_CIRILIC_12.pdf",
      "3 разред основне школе, Преецолиер": "assets/zadaci/2022/PreEcolier_CIRILIC_18.pdf",
      "4 и 5 разред основне школе, Ецолиер": "assets/zadaci/2022/Ecolier_CIRILIC_18.pdf",
      "6 и 7 разред основне школе, Бењамин": "assets/zadaci/2022/BenjaminBHS_CIRILIC_24.pdf",
      "8 и 9 разред основне школе, Цадет": "assets/zadaci/2022/CadetBHS__CIRILIC_24.pdf",
      "1 и 2 разред средње школе, Јуниор": "assets/zadaci/2022/JuniorBHS_CIRILIC_24.pdf",
      "3 и 4 разред средње школе, Студент": "assets/zadaci/2022/StudentBHS_CIRILIC_24.pdf",
    },
    "hrvatski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2022/Skolarac_HR_12.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2022/PreEcolier_HR_18.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2022/Ecolier_HR_18.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2022/BenjaminBHS_HR_24.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2022/CadetBHS__HR_24.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2022/JuniorBHS_HR_24.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2022/StudentBHS_HR_24.pdf",
    },
  },
  "2023": {
    "bosanski/srpski, latinica": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2023/skolarac_BS_2023_com.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2023/proecolier_BS_2023_com.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2023/Ecolier_BS_2023.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2023/Benjamin_BS_2023.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2023/CadetBHS__BS_2023.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2023/Junior_BS_24_2023.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2023/Student_BS_2023.pdf",
    },
    "босански/српски, ћирилица": {
      "2 разред основне школе, Школарац": "assets/zadaci/2023/skolarac_BSCIR_2023_com.pdf",
      "3 разред основне школе, Преецолиер": "assets/zadaci/2023/proecolier_BSCIR_2023_com.pdf",
      "4 и 5 разред основне школе, Ецолиер": "assets/zadaci/2023/Ecolier_BSCIR_2023.pdf",
      "6 и 7 разред основне школе, Бењамин": "assets/zadaci/2023/Benjamin_BSCIR_2023.pdf",
      "8 и 9 разред основне школе, Цадет": "assets/zadaci/2023/CadetBHS__BSCIR_2023.pdf",
      "1 и 2 разред средње школе, Јуниор": "assets/zadaci/2023/Junior_BSCIR_24_2023.pdf",
      "3 и 4 разред средње школе, Студент": "assets/zadaci/2023/Student_BSCIR_2023.pdf",
    },
    "hrvatski": {
      "2 razred osnovne škole, Školarac": "assets/zadaci/2023/skolarac_HR_2023_com.pdf",
      "3 razred osnovne škole, Preecolier": "assets/zadaci/2023/proecolier_HR_2023_com.pdf",
      "4 i 5 razred osnovne škole, Ecolier": "assets/zadaci/2023/Ecolier_HR_2023.pdf",
      "6 i 7 razred osnovne škole, Benjamin": "assets/zadaci/2023/Benjamin_HR_2023.pdf",
      "8 i 9 razred osnovne škole, Cadet": "assets/zadaci/2023/Cadet__HR_2023.pdf",
      "1 i 2 razred srednje škole, Junior": "assets/zadaci/2023/Junior_HR_24_2023.pdf",
      "3 i 4 razred srednje škole, Student": "assets/zadaci/2023/Student_HR_2023.pdf",
    },
  },
}

@Component({
  selector: 'app-klokan-dokumenti',
  templateUrl: './klokan-dokumenti.component.html',
  styleUrls: ['./klokan-dokumenti.component.scss']
})
export class KlokanDokumentiComponent implements OnInit {

  tasks = tasks

  constructor() { }

  ngOnInit() {
  }

  trackingFunction(index: number, obj: { key: string, value: string }) {
    return obj.key + " ... " + obj.value
  }

}
