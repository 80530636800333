import { Component, OnInit, Injectable } from '@angular/core';
import {FormControl,  ReactiveFormsModule } from '@angular/forms';
import {NgForm} from '@angular/forms';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'src/environments/environment';


interface kontaktResponse{
  success:boolean;
  message: string
}

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
@Injectable()
export class KontaktComponent implements OnInit {
  klokan = environment.klokan
  email = environment.klokan ? "klokan.matematicar.ba@gmail.com" : "dabarinformaticarbih@gmail.com"

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  onSubmit(f: NgForm) { 
    
    this.http.post<kontaktResponse>("/api/kontakt.php", f.value, {}).subscribe(data => {
      if (data.success){
        window.alert("Uspješno poslana poruka!")
        
      }
      if (data.success == false){
        window.alert("Greška pri slanju poruke.")
      }
    })
  }
}
