import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";

@Component({
  selector: "zadatak-prvi",
  templateUrl: "zadatak-prvi.html",
  styleUrls: ["./zadaci.scss"]
})
export class ZadatakPrvi {
    public answer="";
    public mode="Prikaži";

    constructor(public dialogRef: MatDialogRef<ZadatakPrvi>) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    showAnswer() {
        if(this.answer== ""){
            this.answer = "show";
            this.mode = "Sakrij";
        } else {
            this.answer = "normal";
            this.mode = "Prikaži";
        }
    }
}
