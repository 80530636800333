import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


//import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';

function parseError(err) {
  const prevodi = {
    "auth/email-already-in-use": "Email koji ste naveli u polju 'Email koordinatora' se vec koristi. Mozda ste se prijavili prethodnih godina, a mozda je neko drugi iz Vase skole vas prijavio. Molimo Vas da kliknete 'Vratite se na prijavu' te se probate prijaviti tu.",
    "auth/invalid-email": "Email nije validnog formata",
    "auth/weak-password": "Sifra nije dovoljno jaka. Molimo unesite kombinaciju velikih/malih slova, brojeva ili znakova",
    "auth/user-disabled": "Korisnik je banovan",
    "auth/user-not-found": "Korisnik s ovim emailom ne postoji. Molimo vas da provjerite da li ste polje 'email' tacno ukucali. Ako jeste, kliknete na dugme 'Registrujte novu skolu' ispod.",
    "auth/wrong-password": "Pogresna sifra. Ukoliko niste sigurni koja Vam je sifra ili ste je zaboravili, molimo Vas da kliknete 'Ako ste zaboravili sifru?' link.",
  };
  if (err.code && prevodi[err.code]) {
    err.message = prevodi[err.code];
  }
  return err;
}

@Component({
  selector: 'app-login-koordinatora',
  templateUrl: './login-koordinatora.component.html',
  styleUrls: ['./login-koordinatora.component.scss']
})
export class LoginKoordinatoraComponent implements OnInit {

  isNotDisplayedMain: any;
  isNotDisplayedForget: any;
  isNotDisplayedSignUp: any;
  user = { username: '', password: '', remember: false };
  registrationSchoolForm: FormGroup;
  errMess: string;
  klokan = environment.klokan

  constructor(public dialogRef: MatDialogRef<LoginKoordinatoraComponent>,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogConfirm: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: string,
    private analytics: AngularFireAnalytics,
  ) {
    this.isNotDisplayedMain = false;
    this.isNotDisplayedSignUp = true;
    this.isNotDisplayedForget = true;
    this.createRegistrationSchoolForm();
    if (data == 'register') {
      analytics.logEvent('page_view', {
        page_location: '/register?through=navbar'
      })
      this.goSignUp()
    } else {
      this.goBack()
    }
  }

  ngOnInit() {
  }

  /* create form */
  createRegistrationSchoolForm() {
    this.registrationSchoolForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      repeatpassword: [null, [Validators.required]],
      schoolName: [null, [Validators.required]],
      schoolCity: [null, [Validators.required]],
      schoolType: [null, [Validators.required]],
      telephone: [null, [Validators.required]],
      schoolEmail: [null, [Validators.email]],
    })
  }

  get getEmailOfKoordinator() {
    return this.registrationSchoolForm.get('email');
  }
  /* Get errors */
  public handleError = (controlName: string, errorName: string) => {
    return this.registrationSchoolForm.controls[controlName].hasError(errorName);
  }

  checkPasswordsMatch() {
    const password = this.registrationSchoolForm.get('password');
    const confirmPassword = this.registrationSchoolForm.get('repeatpassword');

    if (!!!password.value || !!!confirmPassword.value) {
      return false
    }
    if (password.value !== confirmPassword.value) {
      return true;
    }
  }

  async onChangePassword() {
    delete this.errMess;
    try {
      await this.dabarKoordAuthAPI.forgotPassword(this.user.username);
      alert('Email je uspjesno poslan')
    } catch (err) {
      parseError(err);
      this.errMess = err.message;
    }
  }

  async onSubmitLogIn() {
    delete this.errMess;
    try {
      await this.dabarKoordAuthAPI.logIn(this.user);
      this.dialogRef.close();
      this.router.navigateByUrl('/koordinator');
    } catch (err) {
      parseError(err);
      this.errMess = err.message;
    }
  }

  onSubmitSignUp() {
    if (!this.registrationSchoolForm.valid) {
      alert("Forma nije ispravna! Molimo popunite sva polja.\n\nProvjerite da li ste upisali vase ime (vrh forme).")
      return
    }
    const { repeatpassword, ...reducedUser } = this.registrationSchoolForm.value;
    this.registrationSchoolForm.disable();
    delete this.errMess;
    this.dabarKoordAuthAPI.signUp(reducedUser)
      .then(() => {
        this.dialogRef.close();
        this.dialogConfirm.open(DialogRegistrationConfirmDialog, {
          data: {
            schoolName: this.registrationSchoolForm.get('schoolName').value,
            schoolCity: this.registrationSchoolForm.get('schoolCity').value,
            coordName: this.registrationSchoolForm.get('name').value,
            email: this.registrationSchoolForm.get('email').value
          }
        });
      })
      .catch(errorSignUp => {
        parseError(errorSignUp);
        this.errMess = errorSignUp.message
        this.registrationSchoolForm.enable();
      });
  }

  goBack() {
    this.analytics.logEvent('page_view', {
      page_location: '/login'
    })
    delete this.errMess;
    this.isNotDisplayedMain = false;
    this.isNotDisplayedSignUp = true;
    this.isNotDisplayedForget = true;
  }

  goForgetPassword() {
    this.analytics.logEvent('page_view', {
      page_location: '/forgot_password'
    })
    delete this.errMess;
    this.isNotDisplayedMain = true;
    this.isNotDisplayedSignUp = true;
    this.isNotDisplayedForget = false;
  }

  goSignUp() {
    this.analytics.logEvent('page_view', {
      page_location: '/register'
    })
    delete this.errMess;
    this.isNotDisplayedMain = true;
    this.isNotDisplayedSignUp = false;
    this.isNotDisplayedForget = true;
  }
}


@Component({
  selector: 'dialog-data-za-potvrdu-registracije',
  templateUrl: 'dialog-data-za-potvrdu-registracije.html',
})
export class DialogRegistrationConfirmDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}
