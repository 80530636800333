import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private s = new Subscription()
  klokan = environment.klokan

  constructor(
    private router: Router,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
  ) { }

  ngOnInit() {

    this.s.add(this.dabarKoordAuthAPI.getAuthState()
      .subscribe(async (user) => {
        if (user) {
          this.router.navigateByUrl('/koordinator')
        }
      }));
  }
  
  ngOnDestroy() {
    this.s.unsubscribe();
  }

}
