import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DatePipe } from '@angular/common'; // Import DatePipe here

import { AppComponent } from './app.component';
import {CoreModule} from './core/core.module';


import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import {
  MatSnackBarModule,
  MatMenuModule,
  MatSelectModule,
  MatToolbarModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, FormControl, ReactiveFormsModule } from "@angular/forms";
import { environment } from "./../environments/environment";

//import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { PocetnaComponent } from "./pocetna/pocetna.component";
import { UputstvoComponent } from "./uputstvo/uputstvo.component";
import { UceniciComponent } from "./ucenici/ucenici.component";
import { PrimjeriComponent } from "./primjeri/primjeri.component";
import { KontaktComponent } from "./kontakt/kontakt.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { SliderComponent } from "./slider/slider.component";
import { PartneriComponent } from "./partneri/partneri.component";
import { AppRoutingModule } from "./app-routing/app-routing.module";
import { MediaComponent } from "./media/media.component";
import { ZadatakPrvi } from "./primjeri/zadaci/zadatak-prvi";
import { ZadatakDrugi } from "./primjeri/zadaci/zadatak-drugi";
import { ZadatakTreci } from "./primjeri/zadaci/zadatak-treci";
import { ZadatakCetvrti } from "./primjeri/zadaci/zadatak-cetvrti";
import { ZadatakPeti } from "./primjeri/zadaci/zadatak-peti";
import { ZadatakSesti } from "./primjeri/zadaci/zadatak-sesti";
import { ZadatakSedmi } from "./primjeri/zadaci/zadatak-sedmi";
import { ZadatakOsmi } from "./primjeri/zadaci/zadatak-osmi";
import { ZadatakDeveti } from "./primjeri/zadaci/zadatak-deveti";
import { ZadatakDeseti } from "./primjeri/zadaci/zadatak-deseti";
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LoginKoordinatoraComponent } from './login-koordinatora/login-koordinatora.component';
import { DialogRegistrationConfirmDialog } from './login-koordinatora/login-koordinatora.component';
import { KoordDashboardModule } from './koord-dashboard/koord-dashboard.module';
import { FaqComponent } from './faq/faq.component';
import { KlokanDokumentiComponent } from './klokan-dokumenti/klokan-dokumenti.component';
import { NavbarLinkComponent } from './navbar/navbar-link/navbar-link.component';
import { EnvPipe } from './shared/env.pipe';
import { SharedModule } from "./shared/shared.module";
import { KlokanProvjeraRezultataComponent } from './klokan-provjera-rezultata/klokan-provjera-rezultata.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PocetnaComponent,
    UputstvoComponent,
    UceniciComponent,
    PrimjeriComponent,
    KontaktComponent,
    FooterComponent,
    SliderComponent,
    PartneriComponent,
    HomeComponent,
    MediaComponent,
    ZadatakPrvi,
    ZadatakDrugi,
    ZadatakTreci,
    ZadatakCetvrti,
    ZadatakPeti,
    ZadatakSesti,
    ZadatakSedmi,
    ZadatakOsmi,
    ZadatakDeveti,
    ZadatakDeseti,
    LoginKoordinatoraComponent,
    DialogRegistrationConfirmDialog,
    FaqComponent,
    KlokanDokumentiComponent,
    NavbarLinkComponent,
    KlokanProvjeraRezultataComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    AngularFontAwesomeModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTabsModule,
    CoreModule,
    KoordDashboardModule,
    SharedModule,
    ],
  entryComponents: [
    ZadatakPrvi,
    ZadatakDrugi,
    ZadatakTreci,
    ZadatakCetvrti,
    ZadatakPeti,
    ZadatakSesti,
    ZadatakSedmi,
    ZadatakOsmi,
    ZadatakDeveti,
    ZadatakDeseti,
    LoginKoordinatoraComponent,
    DialogRegistrationConfirmDialog,
  ],
  exports: [],
  providers: [DatePipe], // Add DatePipe to the providers array
  bootstrap: [AppComponent]
})
export class AppModule {}
