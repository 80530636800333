import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ucenici',
  templateUrl: './ucenici.component.html',
  styleUrls: ['./ucenici.component.scss']
})
export class UceniciComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
