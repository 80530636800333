import { environment } from "src/environments/environment";

export function getDeadlineDateIndex(dateForTest = new Date()) {
    let ret: number;
    for (
        ret = 0;
        ret < environment.deadlines.length && environment.deadlines[ret].getTime() < dateForTest.getTime();
        ret++);
    return ret;
}

export function getSubmittedDate(lastDabarFile: string) {
    const splitted = lastDabarFile.split('/')
    const last = splitted[splitted.length-1]
    return new Date(parseInt(
        decodeURIComponent(last).split('/')[2].split('_')[0], 10)
    )
}

export const BACKEND_LOCATION = location.host.startsWith('localhost') ? 'http://localhost' : ''

export function getStudentCategory(kategorija) {
switch (kategorija) {
    case "K":
    return "Školarac";
    case "P":
    return "PreEcolier";
    case "E":
    return "Ecolier";
    case "B":
    return "Benjamin";
    case "C":
    return "Cadet";
    case "J":
    return "Junior";
    case "S":
    return "Student";
    case 0:
    return "2, 3 i 4 razred (osnovna škola)";
    case 1:
    return "5 i 6 razred";
    case 2:
    return "7 i 8 razred";
    case 3:
    return "9 i 1 razred";
    case 4:
    return "2, 3 i 4 razred (šrednja škola)";
    /* case "K":
    return "Školarac"
    case "P":
    return "PreEcolier"
    case "E":
    return "Ecolier"
    case "B":
    return "Benjamin"
    case "C":
    return "Cadet"
    case "J":
    return "Junior"
    case "S":
    return "Student" */
}
}