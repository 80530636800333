import { Routes } from '@angular/router';

import { HomeComponent } from '../home/home.component';
import { DabarKoordinatorAuthService } from '../services/dabar.koordinator.auth.service';
import { KoordDashboardComponent } from '../koord-dashboard/koord-dashboard.component';
import { StudentResultsComponent } from '../koord-dashboard/student-results/student-results.component';
import { UploadFilesComponent } from '../koord-dashboard/upload-files/upload-files.component';
import { OsnovniPodaciComponent } from '../koord-dashboard/osnovni-podaci/osnovni-podaci.component';
import { AdminComponent } from '../koord-dashboard/admin/admin.component';
import { FaqComponent } from '../faq/faq.component';
import { BookletOrderComponent } from '../koord-dashboard/booklet-order/booklet-order.component';
import { PocetnaComponent } from '../pocetna/pocetna.component';
import { UputstvoComponent } from '../uputstvo/uputstvo.component';
import { KlokanDokumentiComponent } from '../klokan-dokumenti/klokan-dokumenti.component';
import { KontaktComponent } from '../kontakt/kontakt.component';
import { environment } from 'src/environments/environment';
import { KlokanProvjeraRezultataComponent } from '../klokan-provjera-rezultata/klokan-provjera-rezultata.component';

const homeChildren: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'pocetna' },
  { path: 'pocetna', component: PocetnaComponent },
  { path: 'uputstvo', component: UputstvoComponent },
  { path: 'klokan-dokumenti', component: KlokanDokumentiComponent },
  { path: 'kontakt', component: KontaktComponent },
]

export const routes: Routes = [
  { path: 'home',  component: HomeComponent, children: environment.klokan ? homeChildren : undefined },
  {
    path: 'koordinator',
    canActivate : [DabarKoordinatorAuthService],
    component: KoordDashboardComponent,
    children: [
      { path: 'booklet-order', component: BookletOrderComponent },
      { path: 'students-results', component: StudentResultsComponent },
      { path: 'upload-files', component: UploadFilesComponent },
      { path: 'osnovni-podaci', component: OsnovniPodaciComponent },
      { path: 'admin', component: AdminComponent },
      { path: '', redirectTo: 'upload-files', pathMatch: 'full' },
    ]
  },
  { path: '', redirectTo: environment.klokan ? '/home/pocetna' : '/home', pathMatch: 'full' },
  { path: 'faq', component: FaqComponent },
  { path: 'provjera-rezultata', component: KlokanProvjeraRezultataComponent }
];
