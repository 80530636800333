import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEnvironment } from 'src/environments/environment-interface';

@Pipe({
  name: 'env'
})
export class EnvPipe implements PipeTransform {

  transform(value: keyof IEnvironment): any {
    return environment[value]
  }

}
