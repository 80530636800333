import { Component, Inject, OnInit } from '@angular/core';
//import { UploadService } from '../services/upload.service';
//import { KoordinatorService} from '../services/koordinator.service';
//import { Upload } from '../shared/upload';
//import { AngularFireModule } from 'angularfire2';
//import { AngularFireStorageModule } from 'angularfire2/storage';

import * as firebase from 'firebase/app';

import { Upload } from '../../shared/upload';
import { DabarKoordinatorStorageService } from '../../services/dabar-koordinator-storage.service';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { DabarKoordinatorAuthService } from '../../services/dabar.koordinator.auth.service';
import { DabarKoordinatorService } from '../../services/dabar-koordinator.service';
import { environment } from 'src/environments/environment';
import { FIREBASE_REFERENCES } from 'src/app/core/firebase/firebase.module';
import { BACKEND_LOCATION, getDeadlineDateIndex, getSubmittedDate } from 'src/app/utils';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload.files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  lastUploadedFile: string = undefined;
  passwordsFile: string
  uid: string;
  currentUpload: Upload = new Upload(null);
  selectedFiles: FileList;
  dabarKoordBucket: firebase.storage.Storage;
  dabarKod: string
  submittedDeadlineIndex: number
  klokan = environment.klokan
  numStudents: number

  deadlines = environment.deadlines
  // why user is not allowed to submit ?
  submitForbidErrorMessage: string
  currentDeadlineIndex: number

  private basePath: string = '/uploads';
  public storageRef: firebase.storage.Reference;

  uputstvo = ["Uspješno ste predali tabelu učenika.",
              "Dalje upute ćete dobiti putem email-a kada izazov bude otvoren."]

  private setSubmissionDate() {
    if (this.lastUploadedFile) {
      this.submittedDeadlineIndex = getDeadlineDateIndex(getSubmittedDate(this.lastUploadedFile))
    } else {
      delete this.submittedDeadlineIndex
    }
  }

  constructor(
    @Inject(FIREBASE_REFERENCES.DABAR_KOORDINATORS_FIRESTORAGE) private afStorage: AngularFireStorage,
    private dabarKoordinatorAPI: DabarKoordinatorService,
    private dabarKoordAuthAPI: DabarKoordinatorAuthService,
    private http: HttpClient,
  ) {
    this.currentDeadlineIndex = getDeadlineDateIndex()

    this.dabarKoordBucket = this.afStorage.storage.app.storage(`gs://${environment.firebaseConfig['KOORDINATORS'].storageBucket}/`);
    this.uid = this.dabarKoordAuthAPI.getCurrentUserId();

    this.dabarKoordAuthAPI.getMyKoordinator()
      .subscribe((data) => {
        console.log(data.data())
        this.lastUploadedFile = data.data().lastDabarFile
        this.dabarKod = data.data().dabarKod
        this.passwordsFile = data.data().students_password_file
        this.numStudents = data.data().studentsInExcel
        this.setSubmissionDate()
      });
  }

  ngOnInit() {
  }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
    this.uploadSingle()
  }

  async uploadSingle() {
    let file = this.selectedFiles.item(0);
    this.currentUpload.file = file;
    await this.uploadSingleLikeService(this.currentUpload);
    delete this.selectedFiles
  }

  async uploadSingleLikeService(loc_currentUpload: Upload) {
    const currentTimeInMilliseconds = Date.now().toString();
    const randomId = Math.random().toString().substring(2, 5);

    try {
      this.storageRef = this.dabarKoordBucket.ref(`${this.basePath}/${this.uid}/${currentTimeInMilliseconds + '_' + randomId + '_' + '_' + loc_currentUpload.file.name}`);
      console.log("uploading")
      await this.storageRef.put(loc_currentUpload.file, { customMetadata: { uid: this.uid } });
      const url = await this.storageRef.getDownloadURL();
      loc_currentUpload.url = url;
      console.log("updating coordinator")
      await this.dabarKoordinatorAPI.updateKoordinatorData(this.uid, { lastDabarFile: loc_currentUpload.url });
      console.log("analyzing")
      console.log(this.uid)
      const txt = await this.http.get(
        BACKEND_LOCATION + `/assets/scripts/analyze_xlsx_file.php?id=${this.uid}&project=${environment.backendEnvironment}`,
        { responseType: 'text' }
      ).toPromise();
      this.numStudents = parseInt(txt as any, 10)
      this.lastUploadedFile = url;
      this.setSubmissionDate();
      setTimeout(() => {
        window.alert(txt + " UČENIKA JE DETEKTOVANO\n\n" + this.uputstvo.join("\n\n"))
      }, 100)
    } catch(err) {
      console.error(err)
      alert("Greška: " + (err.error || err.message))
      delete this.numStudents
    }
  }
}
