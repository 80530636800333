import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { LoginKoordinatoraComponent } from '../login-koordinatora/login-koordinatora.component';
import { getDeadlineDateIndex } from '../utils';
declare var jQuery: any;

@Component({
  selector: 'app-pocetna',
  templateUrl: './pocetna.component.html',
  styleUrls: ['./pocetna.component.scss']
})
export class PocetnaComponent implements OnInit {
  deadlines = environment.deadlines
  currentDeadline = getDeadlineDateIndex()

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    (function ($) {
      $(document).ready(function(){
        $("#popupd").hide().fadeIn(1000);
      });
    })(jQuery);
  }

  openRegistration() {
    this.dialog.open(LoginKoordinatoraComponent, { width: '450px', height: '550px', data: 'register' });
  }
}
